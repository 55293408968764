// import {toasteo} from '@deegital/vue-trustup-io-toasteo';

import { useToasteo } from '@deegital/vue-trustup-io-toasteo';

export default () => {
    const store = useAuthStore();

    const logout = async () => {
        useToasteoSuccess('auth.logout.success');

        store.logout();
        await wait(500);
        return (window.location.href = '/');
    };

    return logout;
};
